// _Header.scss

body {
  &.dark {
    header {
      &.active {
        .logo {
          &.light-logo {
            display: none;
          }
          &.dark-logo {
            display: inline-block;
          }      
        }  
      }
      .logo {
        &.light-logo {
          display: inline-block;
        }
        &.dark-logo {
          display: none;
        }
      }          
    }
  }
  &.light {
    header {
      .logo {
        &.light-logo {
          display: none;
        }
        &.dark-logo {
          display: inline-block;
        }
      }  
    }
  }  
}

header {
  &.light {
    background: $white;
  }
  &.dark {
    @extend .ba-transparent !optional; 
  }
  .logo {
    height:44px; 
  }
  .ba-button {
    margin-top: -5px;
  }
  &.active {
    .logo {
     height:44px;
   }   
  }
}