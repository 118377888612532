// _Fonts.scss

$fonts-dir : '/wp-content/themes/ba/build';
//$fonts-dir : '/content/themes/ba/build';

@font-face {
    font-family: 'URW Gothic L';
    src: url('#{$fonts-dir}/fonts/4041-font-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'DejaVu Sans';
    src: url('#{$fonts-dir}/fonts/dejavu-sans-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DejaVu';
    src: url('#{$fonts-dir}/fonts/DejaVuSans.eot');
    src: url("#{$fonts-dir}/fonts/DejaVuSans.eot?#iefix") format("embedded-opentype"),
         url("#{$fonts-dir}/fonts/DejaVuSans.woff") format("woff"),
         url("#{$fonts-dir}/fonts/DejaVuSans.svg") format("svg"),
         url("#{$fonts-dir}/fonts/DejaVuSans.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'URWGothicDemi';
    src: url('#{$fonts-dir}/fonts/urw_gothic_l_demi-webfont.woff2') format('woff2'),
         url('#{$fonts-dir}/fonts/urw_gothic_l_demi-webfont.woff') format('woff'),
         url('#{$fonts-dir}/fonts/urw_gothic_l_demi-webfont.ttf') format('woff');  
    font-weight: 600; /* demi-bold */
    font-style: normal;
}

@font-face {
    font-family: 'URWGothicBook';
    src: url('#{$fonts-dir}/fonts/URWGothicL-Book.eot');
    src: url("#{$fonts-dir}/fonts/URWGothicL-Book.eot?#iefix") format("embedded-opentype"),
         url("#{$fonts-dir}/fonts/URWGothicL-Book.woff") format("woff"),
         url("#{$fonts-dir}/fonts/URWGothicL-Book.svg") format("svg"),
         url("#{$fonts-dir}/fonts/URWGothicL-Book.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
