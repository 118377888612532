// _Search.scss

.fa-search {
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: 10;
}

.autoComplete_wrapper {

    position: relative;

    >input {

        text-indent: 33px;
        border-radius: 10px;
        padding: 15px 20px 12px 20px;
        // text-overflow: ellipsis;
        outline: none;
        transition: all 0.4s ease;
        -webkit-transition: all -webkit-transform 0.4s ease;

        &::placeholder {
            color: #333;
            transition: all 0.3s ease;
            -webkit-transition: all -webkit-transform 0.3s ease;
        }

        &:hover {
            color: #808080;
            transition: all 0.3s ease;
            -webkit-transition: all -webkit-transform 0.3s ease;

            &::placeholder {
                color: rgba(200, 201, 199, 0.6);
                transition: all 0.3s ease;
                -webkit-transition: all -webkit-transform 0.3s ease;
            }
        }

        &:focus {
            color: rgba(200, 201, 199, 1);
            border: 0.06rem solid rgba(200, 201, 199, 0.8);

            &::placeholder {
                padding: 0.1rem 0.6rem;
                font-size: 0.95rem;
                color: rgba(200, 201, 199, 0.4);
            }

            &::selection {
                background-color: rgba(200, 201, 199, 0.15);
            }
        }

        &::selection {
            background-color: rgba(200, 201, 199, 0.15);
        }

    }

    >ul {
        position: absolute;
        max-height: 336px;
        overflow-y: scroll;
        box-sizing: border-box;
        left: 0;
        right: 0;
        margin: 0.5rem 0 0 0;
        padding: 0;
        z-index: 1;
        list-style: none;
        border-radius: 0.6rem;
        background-color: #fff;
        border: 1px solid rgba(33, 33, 33, 0.07);
        box-shadow: 0 3px 6px rgba(149, 157, 165, 0.15);
        outline: none;
        transition: opacity 0.15s ease-in-out;
        -moz-transition: opacity 0.15s ease-in-out;
        -webkit-transition: opacity 0.15s ease-in-out;

        [hidden],
        &:empty {
            display: block;
            opacity: 0;
            transform: scale(0);
        }

        p {
            font-family: 'DejaVu Sans';
            padding: 10px 10px 0px 10px;
        }

        li {
            font-family: 'DejaVu Sans';
            margin: 0.3rem;
            padding: 0.3rem 0.5rem;
            text-align: left;
            font-size: 1rem;
            color: #212121;
            border-radius: 0.35rem;
            background-color: rgba(255, 255, 255, 1);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all 0.2s ease;

            mark {
                background-color: transparent;
                color: $ba-gray-1;
                font-weight: bold;
                padding: 0px!important;
            }

            &:hover {
                cursor: pointer;
                background-color: rgba(200, 201, 199, 0.15);
            }

            [aria-selected="true"] {
                background-color: rgba(200, 201, 199, 0.15);
            }

            span {
                &.term {
                    text-overflow: ellipsis; white-space: nowrap; overflow: hidden;
                }
                &.category {
                    font-size:11px!important;
                    border-radius: 8px!important;
                    font-weight: bold;
                    padding: 4px 8px;
                    background: #fff0c8;
                }
                &.categories {
                    font-size:12px;
                }
            }

        }

    }

}

#menu-portal-menu-1 {
    @include media-breakpoint-down(sm) {
        justify-content: space-evenly !important
    }  
}