// _Colors.scss

.ba-primary {
    color: $ba-primary;
}
.ba-primary-light {
    color: $ba-primary-light;
}
.ba-primary-lighter {
    color: $ba-primary-lighter;
}
.ba-primary-lightest {
    color: $ba-primary-lightest;
}
.ba-black {
    color: $ba-black;
}
.ba-blacker {
    color: $ba-blacker;
}
.ba-blackest {
    color: $ba-blackest;
}
.ba-gray-0 {
    color: $ba-gray-0;
}
.ba-gray-1 {
    color: $ba-gray-1;
}
.ba-gray-2 {
    color: $ba-gray-2;
}
.ba-gray-3 {
    color: $ba-gray-3;
}
.ba-gray-4 {
    color: $ba-gray-4;
}
.ba-offwhite {
    color: $ba-offwhite;
}
.ba-white {
    color: $ba-white;
}
.ba-secondary {
    color: $ba-secondary;
}
.ba-error {
    color: $ba-error;
}
.ba-success {
    color: $ba-success;
}
.ba-warning {
    color: $ba-warning;
}

.ba-bg-primary {
   background-color: $ba-primary;
}
.ba-bg-primary-light {
   background-color: $ba-primary-light;
}
.ba-bg-primary-lighter {
   background-color: $ba-primary-lighter;
}
.ba-bg-primary-lightest {
   background-color: $ba-primary-lightest;
}
.ba-bg-black {
   background-color: $ba-black;
}
.ba-bg-blackest {
   background-color: $ba-blackest;
}
.ba-bg-blacker {
    background-color: $ba-blacker;
 }
 .ba-bg-gray-0 {
    background-color: $ba-gray-0;
 }
.ba-bg-gray-1 {
   background-color: $ba-gray-1;
}
.ba-bg-gray-2 {
   background-color: $ba-gray-2;
}
.ba-bg-gray-3 {
   background-color: $ba-gray-3;
}
.ba-bg-gray-4 {
   background-color: $ba-gray-4;
}
.ba-bg-offwhite {
   background-color: $ba-offwhite;
}
.ba-bg-white {
   background-color: $ba-white;
}
.ba-bg-secondary {
   background-color: $ba-secondary;
}
.ba-bg-error {
   background-color: $ba-error;
}
.ba-bg-success {
   background-color: $ba-success;
}
.ba-bg-warning {
   background-color: $ba-warning;
}

