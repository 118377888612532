// _Typography.scss

html, body,
p, 
.ba-p {
  font-family: 'DejaVu Sans';
  font-size: 16px;
} 

h1 {
  @extend .h1;
  font-family: 'URWGothicDemi';
  font-size: 42px;
  text-transform: uppercase;
  line-height: 0.95;
  @include media-breakpoint-up(md) {
    font-size: 62px;
  }
} 

h2 {
  @extend .h2;
  font-family: 'URWGothicDemi';
  font-size: 48px;
  text-transform: uppercase;
} 

h3 {
  @extend .h3;
  font-family: 'URWGothicDemi';
  font-size: 32px;
  text-transform: uppercase;
} 

h4 {
  @extend .h4;
  font-family: 'URWGothicDemi';
  font-size: 22px;
} 

.ba-small {
  @extend .h6;
  font-family: 'DejaVu Sans';
  font-size: 14px;
} 

.ba-label {
  @extend .h5;
  font-family: 'URWGothicDemi';
  font-size: 16px;
  text-transform: uppercase;
}