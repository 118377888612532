// _utilities.scss

.hide-me {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  padding: 0;
  opacity: 0;
}

.tag{ 
  @extend .btn-sm;
  border: 1px solid $ba-black;
  border-radius: 4px;
  font-size: 14px;
  padding: .05rem .35rem;
  line-height: 1;
  margin: 0;
}

.has-hover {
  transition: box-shadow 0.23s ease-in-out;
  &:hover {
    @extend .shadow;
  }
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
}