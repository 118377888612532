// _Base.scss

$fonts-dir : '/wp-content/themes/ba/build';
$images-dir : '/wp-content/themes/ba/build/img';

body {
  min-height: 100vh;
  overflow-x: hidden;

  &.dark {
    background: $ba-black;
  }
}

main {
  flex: 1 1 0%;
}

a.ba-primary,
a.ba-primary:hover {
  color: $ba-primary;
  text-decoration: none;
}

a.ba-white,
a.ba-white:hover {
  color: $ba-primary;
  text-decoration: none;
}

a.ba-gray-1,
a.ba-gray-1:hover {
  color: $ba-gray-1;
  text-decoration: none;
}

hr {
  opacity: 1;
}

.blockquote {
  margin-bottom: 2rem;
  display: flex;
}

blockquote {
  p {
    font-family: 'URWGothicDemi';
    font-size: 22px !important;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 32px !important;
    }
  }

  cite {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: $ba-gray-3;

    &:before {
      content: "\2014\00A0";
    }
  }
}

.ba-button,
.data,
.img,
.img-bg,
.media-img,
.icon,
.social {
  svg {
    fill: currentColor;
  }
}

.wp-block-image {
  img {
    max-width: 100%;
    height: auto;
  }
}

.media {
  @extend .mt-3;
  @extend .d-flex;

  >.media-img {
    @extend .flex-shrink-0;
  }

  >.media-body {
    @extend .flex-grow-1;
    @extend .ms-3;
  }

  &.sign-post-slim {
    @extend .mt-0;
    @extend .p-3;
    @extend .rounded-3;
    @extend .has-hover;

    transition: 0.23s ease-in-out;

    &:hover {
      background-color: $ba-blackest;
    }

    .media-img {
      max-width: 120px;

      svg {
        height: 56px;
      }

    }
  }
}

.content {
  ul {
    padding-left: 30px;
  }
}

img {
  &.alignright {
    @include media-breakpoint-up(sm) {
      float: right;
    }
  }

  &.aligncenter {
    display: block;
    margin: 0 auto;
  }
}

// CTAs
#sticky-cta {
  transition: 0.23s ease-in-out;
  opacity: 0;
  visibility: hidden;

  &.active {
    @include media-breakpoint-up(lg) {
      opacity: 1;
      visibility: visible;
    }
  }
}

.single-knowledge_base,
.page-template-portal--page-search,
.page-template-portal--page {
  .navbar {
    z-index: 1020;
  }
}

.single-knowledge_base {
  &.depth-1 {
    main {
      top: 205px;
    }
    main,
    .knowledge-base--wrapper {
      height: calc(100vh - 225px) !important;
      @include media-breakpoint-up(md) {
        height: calc(100vh - 210px) !important;
      }
    }
  }
  &.depth-2 {
    main {
      top: 235px;
    }
    main,
    .knowledge-base--wrapper {
      height: calc(100vh - 255px) !important;
      @include media-breakpoint-up(md) {
        height: calc(100vh - 240px) !important;
      }
    }
  }
  &.depth-3 {
    main {
      top: 275px;
    }
    main,
    .knowledge-base--wrapper {
      height: calc(100vh - 295px) !important;
      @include media-breakpoint-up(md) {
        height: calc(100vh - 280px) !important;
      }
    }
  }

}

.page-template-portal--page-search {
  main {
    top: 205px;
    height: calc(100vh - 225px) !important;
    @include media-breakpoint-up(md) {
      height: calc(100vh - 210px) !important;
    }
  }
  .knowledge-base--wrapper {
    height: calc(100vh - 225px) !important;
    @include media-breakpoint-up(md) {
      height: calc(100vh - 210px) !important;
    }
  }
}

.page-template-portal--page-search,
.single-knowledge_base {
  header {
    @extend .position-fixed;
    @extend .w-100;
  }

  .kb-utility {
    header {
      @extend .position-fixed;
    }
  }

  main {
    position: fixed;
    width: 100%;
  }

  .knowledge-base--wrapper {
    position: absolute;
    top: 0px;
  }

  #knowledge-base--content {
    @extend .h-100;
  }

  footer {
    position: relative;
    top: 100vh !important;
  }
}

#knowledge-base--content {
  display: none;
}