// _KnowledgeBase.scss

body.article,
body.book {

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 15px!important;
    font-weight: bold !important;
  }
  h1 {
    font-size: 32px !important;
    color: $ba-blacker !important;
    text-transform: initial !important;
    border-bottom: none !important;
  }

  h2 {
    font-size: 30px !important;
    color: $ba-blacker !important;
    text-transform: initial !important;
  }

  h3 {
    font-size: 28px !important;
    color: $ba-gray-0 !important;
    text-transform: initial !important;
  }

  h4 {
    font-size: 26px !important;
  }

  h5 {
    font-size: 24px !important;
  }

  h5 {
    font-size: 22px !important;
  }

  h4,
  h5,
  h6 {
    color: $ba-gray-0 !important;
    text-transform: initial !important;
  }

  .sect1 {
    padding-bottom: 20px !important;

    h2 {
      margin-top: 20px !important;
    }

    a {
      color: $ba-secondary !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .step {
    //margin-left: 28px;
    display: block;
  }

  #header,
  #content {
    margin-right: auto !important;
    margin-left: auto !important;
    padding: 0 5% !important;
  }

  #header {

    @include media-breakpoint-down(sm) {
      padding-top: 5.2%;
    }
    max-width: 100% !important;
    width: 100% !important;
    padding: 0 5% !important;
  }

  #content {
    @include media-breakpoint-down(sm) {
      max-width: 90% !important;
      width: 90% !important;
    }

    max-width: 100% !important;
    width: 100% !important;

  }

  #footer {
    display: none !important;
  }

  &.toc-left {

    #header,
    #content,
    #footnotes,
    #footer {
      max-width: 100% !important;
    }
  }
}


#header {
  >h1 {
    &:first-child {

      //display: none !important;
      //.margin-top: 20px!important;
      @include media-breakpoint-down(sm) {
        margin-top: 20px !important;
      }
    }
  }
}

#toc {

  background: $ba-gray-4 !important;
  margin-top: 0px !important;

  @include media-breakpoint-up(md) {
    margin-top: 8px !important;
    padding-top: 30px !important;
  }

  #toctitle {
    display: none;
  }

  >ul {
    margin-left: 5px !important;
    padding-left: 0px !important;
    font-family: "URWGothicDemi" !important;

    >li {
      border-bottom: 1px solid $ba-blacker !important;
      padding-bottom: 15px;
      margin-bottom: 15px;

      a {
        color: $ba-blacker;
        padding: 5px 0px;
      }
    }
  }
}
