// _Footer.scss

.footer {
/*  @include media-breakpoint-up(md) {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }*/
  .logo {
    height: 44px;
  }
  .address {
    line-height:2em;
    @include media-breakpoint-up(md) {
      padding-top: 40px;
    }
    p {
      font-size: 12px;
    }
  }
  li {
    padding: 3px 0;
    @include media-breakpoint-up(md) {
      padding: 5px 0;
    }
    a {
      color: $ba-white; 
      text-decoration: none;
      &:hover {
        color: $ba-primary;
      }    
    }    
  }
  .social {
    svg {
      vertical-align: middle !important;
      width: 32px;
      height: 32px;
    }
  }
}
