// _Overrides.scss

:root {
    --bs-gutter-x: 14px;
    --bs-gutter-y:14px;
}

@include media-breakpoint-down(sm) {
    :root {
        --bs-gutter-x: 20px;
        --bs-gutter-y: 20px;
    }
}

@include media-breakpoint-down(md) {
    :root {
        --bs-gutter-x: 22px;
        --bs-gutter-y: 22px;
    }
}

@include media-breakpoint-up(md) {
    :root {
        --bs-gutter-x: 28px;
        --bs-gutter-y: 28px;
    }
}